@use "node_modules/@angular/material/index" as mat;
@include mat.elevation-classes();

@import "src/theme";

/* ****************************************************************************************************************** */
/* Styles des tableaux */
/* Éléments de pagination */

mat-paginator.mat-mdc-paginator {
  background-color: transparent;
}

.table-actions, .table-bottom {
  mat-paginator.mat-mdc-paginator {
    font-size: inherit;

    .mat-mdc-paginator-container {
      .mat-mdc-select-value {
        font-size: inherit;
      }

      .mat-mdc-paginator-range-actions {
        .mat-mdc-paginator-range-label {
          margin: 0 0.5em;
        }

        button {
          margin-right: 0;
        }
      }
    }
  }
}

.table-actions {
  mat-paginator.mat-mdc-paginator {
    float: right;
    margin-top: -9px;

    .mat-mdc-paginator-container {
      min-height: auto;

      .mat-mdc-paginator-range-actions {
        margin-top: 3px;
      }
    }
  }
}

th.mat-mdc-header-cell.quantite,
th.mat-mdc-cell-epona.quantite {
  max-width: 120px;
  white-space: normal
}

table td.montant,
table td.montant input {
  text-align: right;
}

.long-text {
  max-width: 500px;
  white-space: pre-wrap;
}

/* Mise en forme de la colonne de sélection d'une ligne et de résultat d'une mise à jour */
table tr .mat-column-checkBox {
  text-align: center;
}
table tr td.mat-column-checkBox {
  background-color: inherit;
  transition-duration: 3s;
  transition-property: background-color, color;
}

.long-text {
  max-width: 500px;
  white-space: pre-wrap;
}

/* Indicateur d'erreur ou de succès sur chaque ligne */
/* Par défaut aucun indicateur n'est affiché
  et l'indicateur adequat s'affiche si la classe 'failure' ou 'success' est présent sur le <tr> */
table div.update-result {
  margin-top: -10px;
  span.success, span.failure {
    color: white;
    display: none;
    font-size: 16px;
  }
}
table tr.success td.mat-column-checkBox {
  background-color: #51A351;
  span.success {
    display: inline-block;
  }
}
table tr.failure td.mat-column-checkBox {
  background-color: #BD362F;
  span.failure {
    display: inline-block;
  }
}

/* Commentaire affiché dans un tableau (avec lien pour modification) */
a.commentaire-ligne {
  display: block;
  width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
div.commentaire-ligne {
  white-space: pre-wrap;
}

/* .mat-mdc-cell + padding: 0 16px; */
.mat-mdc-cell-epona {
  border-bottom-color: var(--mat-table-row-item-outline-color, var(--mat-sys-outline, rgba(0, 0, 0, 0.12)));
  border-bottom-style: solid;
  border-bottom-width: var(--mat-table-row-item-outline-width, 1px);
  padding: 0 16px;
  text-align: left;
}

/* ****************************************************************************************************************** */
/* Style des formulaires */

button {
  white-space: nowrap;
}

/* Bouton de masquage / affichage de l'entête */
button.handle {
  background-color: transparent;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  color: #666666;
  cursor: pointer;
  display: block;
  font-size: 14px;
  height: 40px;
  margin-right: -16px;
  margin-top: 10px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 16px;
}

mat-card.masquee {
  display: none;
}

/* Formulaire sur plusieurs colonnes */
.conteneur-colonne {
  display: table;
  width: 100%;
}
.colonne {
  display: table-cell;
  .mat-mdc-form-field-infix {
    min-width: auto;
  }
}
.conteneur-colonne .colonne:not(:first-child) {
  padding-left: 20px;
}

mat-form-field ul {
  margin: 0;
  padding-left: 0;

  li {
    list-style-type: none;
  }
}

div.mat-mdc-form-field-hint-wrapper {
  padding: 0;
}
div.mat-mdc-form-field-hint-spacer {
  display: none;
}

/* ****************************************************************************************************************** */
/* Styles des dialogs */

.question {
  font-weight: bold;
}

/* Pour éviter un scroll disgracieux (ex. : dialog de paramétrage des bâtiments) */
mat-dialog-content mat-tab-body > div {
  padding-bottom: 8px;
}

mat-dialog-content dt {
  font-weight: bold;
}

/* ****************************************************************************************************************** */
/* Styles des onglets */

.mdc-tab.mat-mdc-tab-disabled {
  cursor: not-allowed;
}

/* ****************************************************************************************************************** */
/* Styles des matToolTip */

.error-mattooltip {
  background: #f44336;
}

/* ****************************************************************************************************************** */
/* Mise en forme générique de texte */

.erreur {
  color: red;
}
.avertissement {
  color: orange;
}
.succes {
  color: green;
}

/* ****************************************************************************************************************** */
/* Mise en forme des composants de sélection d'un lieu (Épona ou VEM) */

epona-select-lieu, epona-lieu-filter {
  form.in-filter {
    background-color: #3F51B5;
    margin-left: -20px;
    padding-bottom: 10px;
    padding-top: 10px;

    mat-form-field {
      width: 300px;

      .mdc-text-field--filled {
        background-color: transparent;
      }

      /* Positionnement des libellés */
      .mdc-text-field .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mdc-floating-label {
        color: #FFFFFF;
        font-size: 1.5rem;
      }

      /* Mise en forme de la liste de sélection */
      mat-select {
        background-color: #FFFFFF;
        border-radius: .25rem;
        padding: .375rem .75rem;

        /* Positionnement de la flèche */
        .mat-mdc-select-arrow-wrapper {
          transform: none;
        }
      }
    }
  }

  form.in-form {
    mat-form-field {
      display: inline-block;
      min-width: unset;
      width: 49%;
    }

    mat-form-field.lieu {
      margin-left: 2%;
    }
  }

  mat-icon {
    color: white;
    cursor: help;
    margin-left: 10px;
    vertical-align: middle;
  }
}

/* Positionnement du panneau présentant les options de chaque champ de sélection */
.select-lieu-filter-panel.select-panel.mat-mdc-select-panel {
  margin-left: 20px;
}

/* ****************************************************************************************************************** */
/* Mise en forme des totaux et totaux par taux de TVA */

.fiche .lignes {
  mat-card.ht-tva, mat-card.ttc {
    display: table;
    margin-left: auto;
    margin-right: 0;
    margin-top: 1em;
    width: auto;

    th {
      padding-right: 1em;
    }

    th.tauxTva {
      text-align: right;
    }
  }
}

/* ****************************************************************************************************************** */
/* Tableau de bord */

// Bouton en haut à droite de chaque card
.envoi-link {
  position: absolute;
  right: 10px;
  top: 10px;
}

.tdb-table {
  width: 100%;
}

/* ****************************************************************************************************************** */
/* Fiche d'une commande */

epona-commande-entete input[type="number"] {
  -moz-appearance: textfield;
}

/* Permet de ne pas élargir la largeur des champs ayant un maxSuffix */
epona-commande-entete .mat-mdc-form-field-icon-suffix {
  margin-left: -32px;
}

/* Permet de ne pas élargir la largeur du champ de date livraison souhaitée à cause du picker */
epona-commande-entete .date-picker .mat-mdc-form-field-icon-suffix {
  margin-left: -44px;
}

/* ****************************************************************************************************************** */
/* Fiche d'entrée-sortie */

epona-commande-lignes > div,
epona-entree-sortie-lignes > div,
epona-fdnc-commande-livraison > div {
  padding: 0 15px 20px 15px;
}
epona-entree-sortie-entete .mat-mdc-form-field-icon-suffix {
  margin-left: -36px;
}
epona-entree-sortie-entete .mat-mdc-form-field-icon-suffix mat-icon {
  margin: 0;
}

/* ****************************************************************************************************************** */
/* Paramétrage des lieux */

/* Mise en forme des champs de saisie */
mat-dialog-content.lieu .mat-form-field-row {
  text-align: center;
}
mat-dialog-content.lieu .mat-form-field-row mat-form-field {
  width: 270px;
}

/* Champ de saisie du lieu BNS */
mat-dialog-content.lieu .batiment-bns button[matSuffix] {
  font-size: 24px;
  min-width: auto;
  padding: 0;
}

/* ****************************************************************************************************************** */
/* Saisie d'un engagement juridique */

epona-engagement-juridique-entete mat-form-field {
  width: 300px;
}

epona-engagement-juridique-lignes {
  button.lock {
    font-size: 18px;
    line-height: normal;
    position: absolute;
    right: 0;
  }

  table mat-form-field.mat-mdc-form-field {
    width: 150px;
  }
  td.mat-column-montantHt mat-form-field.mat-mdc-form-field {
    width: 100px;
  }
}

/* ****************************************************************************************************************** */
/* Saisie d'un service fait */

epona-service-fait-entete mat-form-field {
  width: 300px;
}

/* ****************************************************************************************************************** */
/* Fiche d'une FDNC */

epona-fdnc .mat-mdc-tab-body-content {
  padding-top: 20px;
}

/* ****************************************************************************************************************** */
/* Ajout d'une ligne d'entrée/sortie / modification d'une ligne / détail d'un écart */

.details-ecart table mat-form-field.mat-mdc-form-field {
  width: auto;
}
