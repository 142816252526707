/* *********************************************************************** */
/* /!\               Fichier issu de angular-app-commons               /!\ */
/* /!\ Merci d'avertir le Cnous si une modification doit être apportée /!\ */
/* *********************************************************************** */

@import '../node_modules/normalize.css';
@import '../node_modules/ngx-toastr/toastr';

/* ****************************************************************************************************************** */

html {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  line-height: 1.5;
}

*, ::after, ::before {
  box-sizing: border-box;
}

abbr {
  cursor: help;
}

fieldset {
  padding: 0;
  border: 0;
}

legend {
  margin-bottom: .5rem;
}

/* ****************************************************************************************************************** */
/* Styles du contenu de la page */
.content {
  padding-left: 20px;
}

/* ****************************************************************************************************************** */
/* Formulaires de connexion (choix de l'établissement, login) */

.etablissement-card,
.login-card {
  display: block;
  max-width: 400px;
  margin: 5em auto;
  text-align: center;
}
.etablissement-card mat-form-field,
.login-card mat-form-field {
  display: block;
}

/* ****************************************************************************************************************** */
/* Messages d'alerte (reprise de Bootstrap) */
.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

mat-dialog-content {
  .alert-danger, .alert-info, .alert-success, .alert-warning {
    margin-left: auto;
    margin-right: auto;
    max-width: 480px;
  }
}

/* ****************************************************************************************************************** */
/* MatCard */

mat-card {
  padding: 0 16px 16px 16px;
}
mat-card mat-card-header {
  margin-bottom: 12px;
}

/* ****************************************************************************************************************** */
/* Fenêtres de dialog */

mat-dialog-container [mat-dialog-title] {
  margin-bottom: 20px;
  padding: 0;
}
mat-dialog-container [mat-dialog-title] + .alert {
  margin-top: -20px;

  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
}
/* Marge incompréhensible au-dessus de la barre de titre */
mat-dialog-container [mat-dialog-title]::before {
  display: none;
}

mat-dialog-container .space {
  flex: 1 1 auto;
}

mat-dialog-container .mat-mdc-dialog-content mat-form-field {
  display: block;
}

.question {
  font-weight: bold;
}

/* ****************************************************************************************************************** */
/* Onglets */

/* Ligne sous les onglets dans un mat-tab-group */
mat-tab-header {
  border-bottom: 1px solid rgba(0, 0 , 0, 0.12);
}

/* ****************************************************************************************************************** */
/* Boutons d'aide */

aide.aide-contextuelle mat-icon {
  display: inline;
  font-size: 18px;
  vertical-align: top;
  line-height: 1.15;
}
aide.aide-globale {
  position: absolute;
  right: 1.5em;
  top: 1.5em;
}

/* ****************************************************************************************************************** */
/* Contrôles de formulaires */

mat-form-field {
  /* Espace sous le mat-form-field pour accentué la séparation avec le suivant en cas d'erreur ou de hint */
  margin-bottom: 6px;
  /* Padding au-dessus de mat-form-field  */
  padding-top: 0.25em;
}

/* Champs de formulaires désactivés */
mat-form-field.mat-form-field-disabled,
mat-form-field.mat-form-field-disabled input {
  cursor: not-allowed;
}

/* Marge permettant de décoller les matSuffix du bord des mat-form-field */
mat-form-field [matSuffix] {
  margin-left: 8px;
  margin-right: 12px;
}

mat-form-field mat-datepicker-toggle[matSuffix] {
  margin-right: 0;
}


/* Panneau présentant les options des champs de sélection */
.select-panel {
  min-width: fit-content;
}
.select-panel mat-option.mat-mdc-option span.mdc-list-item__primary-text {
  padding-right: 16px;
  white-space: nowrap;
}
.select-panel mat-option.mat-mdc-option mat-pseudo-checkbox {
  margin-left: 0;
  margin-right: 16px;
}

/* Plusieurs champs sur une ligne */
form .mat-form-field-row {
  mat-form-field {
    display: inline-block;
    vertical-align: top;
  }
  mat-form-field:not(:first-child) {
    margin-left: 6px;
  }
}

/* ****************************************************************************************************************** */
/* IHM de recherche */

.crous-recherche {
  display: flex;
  flex-wrap: nowrap; // Pas de passage sur plusieurs lignes
}
.crous-recherche mat-card > mat-divider {
  position: relative;
  margin-bottom: 20px;
}

/* Mise en forme des formulaires de recherche */
.crous-recherche-filtre {
  flex-grow: 0; // Le filtre ne s'élargit pas

  mat-form-field {
    display: block;
    padding-bottom: 0.25em;
  }

  /* Mise en forme des champs non vides */
  mat-form-field.not-empty mat-label,
  mat-form-field.not-empty .mat-mdc-text-field-wrapper {
    background-color: #EEEEFF;
    border-color: #000000;
    color: #000000;
  }

  .filtre-actions {
    text-align: center;
  }
}

/* Mise en forme des résultats de recherche */
.crous-recherche-liste {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
}
.crous-recherche-liste mat-card {
  display: block;
  margin-left: 20px;
  margin-right: 20px;
}

/* ****************************************************************************************************************** */
/* Tableaux */

/* Boutons associés à un tableau */
.table-actions {
  margin-bottom: 10px;
  margin-top: 10px;
}
.table-actions button {
  margin-right: 8px;
}

/* Liste de sélection des colonnes affichées */
form-displayed-columns form {
  display: inline-block;
  height: 30px;
  vertical-align: bottom;

  .mat-mdc-select-arrow-wrapper {
    margin-left: 5px;
  }
}

.nb-results {
  margin-top: 5px;
  position: absolute;
  right: 20px;
}

.content .crous-recherche-liste table,
.content .lignes table {
  min-width: auto;

  button:not([disabled]) {
    color: inherit;
  }
}

.content th {
  font-weight: bold;
}

/* Mise en forme des champs de formulaire inclus dans des tableaux */
table mat-form-field.mat-mdc-form-field {
  width: 80px;

  /* Mise en forme du champ de saisie */
  .mat-mdc-text-field-wrapper.mdc-text-field {
    background-color: transparent;
    padding: 0 2px;

    .mat-mdc-form-field-infix {
      min-height: auto;
      padding: 0;

      input[type="number"] {
        -moz-appearance: textfield;
      }
    }
  }

  /* Mise en forme du champ d'erreur */
  .mat-mdc-form-field-subscript-wrapper {
    .mat-mdc-form-field-error-wrapper {
      line-height: 1.3;
      padding: 0;
    }
  }
}

tr.no-record td {
  font-style: italic;
  text-align: center;
}

/* ****************************************************************************************************************** */
/* IHM de consultation et de saisie d'une fiche */

.fiche {
  display: flex;
  flex-wrap: nowrap; // Pas de passage sur plusieurs lignes
}
.entete {
  flex-grow: 0; // L'entête ne s'élargit pas
  position: relative;
}
.lignes {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  min-width: 30%;
}

.fiche .lignes mat-card {
  display: inline-block;
  margin-left: 20px;
  margin-right: 20px;
}

.entete mat-form-field {
  display: block;
}

/* Boutons d'action */
.form-actions {
  margin: 10px auto 10px 0;
}
.form-actions button {
  margin-right: 8px;
}

